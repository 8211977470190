<template>
    <button @click="exportToExcel" class="btn btn-warning btn-rounded btn-sm mr-2">
        <i class="fa fa-download"></i>
    </button>
  </template>
  
  <script>
  import exportFromJSON from 'export-from-json';
  
  export default {
    props: {
      jsonData: {
        type: Array,
        required: true
      }
    },
    methods: {
      exportToExcel() {
        const data = this.jsonData;
        const fileName = 'data';
        const exportType = 'csv';

        function flattenObject(obj, prefix = '') {
          const result = {};
          Object.keys(obj).forEach(key => {
            const value = obj[key];
            const newKey = prefix ? `${prefix}.${key}` : key;
            if (value === null) return; // ignore null values
            if (typeof value === 'object') {
              Object.assign(result, flattenObject(value, newKey));
            } else {
              result[newKey] = value;
            }
          });
          return result;
        }
        // Flatten the JSON data using the recursive function
        const flattenedData = data.map(item => flattenObject(item));
        // Format phone number columns as text
        const phoneNumberColumns = ['phone_number', 'phone_no1', 'phone_no2']; // adjust column names as needed
        flattenedData.forEach(row => {
          phoneNumberColumns.forEach(column => {
            if (row[column]) {
              row[column] = `'${row[column]}`; // add an apostrophe to format as text
            }
          });
        });
        // Set the encoding and font to support Arabic characters

        const options = {
          encoding: 'UTF-8',
          font: 'Arial Unicode MS',
          bidi: true
        };
        // Export the flattened data to Excel
        exportFromJSON({ data: flattenedData, fileName, exportType, options,withBOM:true });
      }
    }
  }
  </script>